/** @format */
import { BrowserProfilingIntegration, BrowserTracing, Replay, captureException, init } from "@sentry/react";

import { dist, environment, localhost, show, version } from "version";

import { isbot } from "isbot";

import isSSR from "isssr";

import sentry from "./config/index.json";

// Chck if enabled
const enabled = !localhost && !isSSR && !isbot(navigator.userAgent);

// Remove this
if (show[environment]) console.log(`Observability is ${enabled ? "enabled" : "disabled"}.`);

// Only enabled this for remote and non-bot traffic
if (enabled && !isSSR) {
  init({
    // The dsn/key
    dsn: sentry.dsn,

    // The release version
    release: `${sentry.app}@${version}`,

    // Dist (build version created during the build)
    dist: dist,

    // Enabled (turn off on localhsot)
    enabled: sentry.enabled,

    // Set if we need to debug or not
    debug: sentry.debug,

    // Set the environment we're on
    environment: environment,

    // Set max number of breadcrumbs
    maxBreadcrumbs: sentry.maxBreadcrumbs,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production

    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.25,

    // #TODO:  Browser profiling and replaying
    integrations: [new BrowserProfilingIntegration(), new BrowserTracing(), new Replay()],
  });
}

// /**
//  * Captures and reports an exception.
//  *
//  * @param {Error} e - The exception to be captured.
//  */
export function exception(e) {
  if (enabled) {
    console.error(e);
    captureException(e, { time: Date.now() });
  }
}
